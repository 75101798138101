<template>
	<div :class="themeClass" class="width-fill margin-t-10 bg-fff">
		<p class="padding-13 flex-row-align-center" v-model="myCard">
			<span class="font-size18 font-color-000 font-weight700">我的银行卡</span>
			<span class="font-color-333 margin-l-10">已添加 
				<i class="font-color-FF0000">{{myCard.CreditCard}}</i> 张信用卡， 
				<i class="font-color-FF0000">{{myCard.CashCart}}</i> 储蓄卡
			</span>
		</p>
		<div class="centerContent padding-lr-13 flex-row-wrap">
			<div class="carList border-F2F2F2 border-E4E4E4 radius2 margin-r-20 margin-b-15" v-for="(item,index) of bankCardData" :key="index">
				<div class="topList padding-13 bg-F8F9FE">
					<p class="flex-row-between-wrap flex-row-align-center">
						<span>
							<img class="imgBox margin-rl-5 radius30" :src="item.img" alt="" />
							<span class="font-size16 font-weight700">{{item.bankName}}</span>
						</span>
						<span class="pinkBg font-color-fff text-center font-size13 radius2">{{item.cardTyep}}</span>
					</p>
					<p class="font-size20 font-weight700 margin-t-10" v-html="$options.filters.BankCardFormat(item.cardNum)">
					</p>
				</div>
				<div class="botList padding-13 font-size13 font-color-999">
					<p class="flex-row-between-wrap flex-row-align-center margin-b-5">
						<span>持卡人姓名：{{item.personName}}</span>
						<span class="color-theme hideBox">设为默认</span>
					</p>
					<p class="flex-row-between-wrap flex-row-align-center">
						<span>手机号：{{item.phoneNum | formatPhone}}</span>
						<span class="color-theme hideBox" @click="deleteBtn(index)">删除</span>
					</p>
				</div>
			</div>
			<div class="carList pointer border-F2F2F2 border-E4E4E4 radius2 margin-r-20 margin-b-15 flex-row-center-center"  @click="addBankCard">
				<div class="text-center">
					<span class="iconfont font-size40 font-color-ccc">&#xe619;</span>
					<div class="font-size16 font-color-ccc font-weight700 margin-t-6">添加银行卡</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	export default{
		data(){
			return{
				myCard:{
					CreditCard:3,//信用卡
					CashCart:2 //储蓄卡
				},
				Show: false,
				bankCardData: [{
						img:require("../../../assets/imgs/GoldCoinMall/avator.png"),
						bankName:"招商银行",
						cardTyep:"信用卡",
						cardNum:"6226000011118888",
						personName:"王佳慧",
						stationType:true,
						phoneNum:"15142563258",
						deleteType:1,
						moren: true
					},
					{
						img:require("../../../assets/imgs/GoldCoinMall/avator.png"),
						bankName:"中国银行",
						cardTyep:"储蓄卡",
						cardNum:"6226000011118888",
						personName:"梁宽立",
						stationType:true,
						phoneNum:"15142563258",
						deleteType:1,
						moren: false
					},
					{
						img:require("../../../assets/imgs/GoldCoinMall/avator.png"),
						bankName:"新疆银行",
						cardTyep:"储蓄卡",
						cardNum:"6226000011118888",
						personName:"女年份",
						stationType:true,
						phoneNum:"15142563258",
						deleteType:1,
						moren: false
					},
					{
						img:require("../../../assets/imgs/GoldCoinMall/avator.png"),
						bankName:"西安银行",
						cardTyep:"储蓄卡",
						cardNum:"6226000011118888",
						personName:"王老师",
						stationType:true,
						phoneNum:"15142563258",
						deleteType:1,
						moren: false
					}
				],
				
			}
		},
		computed: {
			...mapGetters(['getThemeName']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
		components: {
		},
		mounted() {
			//this.getList();
		},
		methods:{
			deleteBtn(index){
				console.log(index);
				this.bankCardData.splice(index,1);
			},
			addBankCard(){
				this.$router.push({
					path: '/PersonalContent/NewBankCard',
					query: {
						pageType: "新增银行卡"
					},
				});
			}
		},
		watch:{
			
		},
		filters: {
		            //格式化银行账户
				BankCardFormat: function (value) {
					value = value.toString();
					if (value && value.length > 8) {
						return (`${value.substring(0, 4)}`+"<span style='margin:0px 5px;letter-spacing:4.5px'>"+`${"·".repeat(value.length - 8).replace(/(.{4})/g, `
							$1 `)}`+"</span>"+`${value.slice(-4)}`);
					}
					return value;
				},
				formatPhone:function (val) {
					return `${val.substring(0,3)}****${val.substring(val.length-3)}`
			    }

		}
	}
</script>

<style lang="scss" scoped>
	.bg-theme {
		@include themify($themes) {
			background-color: themed('themes_color');
		}
	}
	
	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}
	i{
		font-style: normal;
	}
	.topList img{
		height: 20px;
		width: 20px;
	}
	.pinkBg{
		width: 46px;
		height: 18px;
		line-height: 18px;
		background-color: #FF7674;
	}
	.centerContent{
		box-sizing: border-box;
		.carList{
			width: calc(33% - 13px);
			min-height: 150px;
		}
		.carList:nth-child(3n+3) {
			margin-right: 0px;
		}
		.imgBox{
			vertical-align: top;
		}
		.hideBox{
			display: none;
		}
		.carList:hover .hideBox{
			display: inline-block;
		}
		
	}
	
</style>
